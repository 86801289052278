@font-face {
  font-family: "AvenirHeavy";
  src: url("./Fonts/Avenir\ Heavy.ttf");
}
@font-face {
  font-family: "AvenirLight";
  src: url("./Fonts/Avenir\ Light.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Caption&family=Pacifico&family=Yellowtail&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;700&family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gugi&family=Roboto+Mono:wght@300;700&family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

* {
  margin: 0;
  padding: 0;
  list-style: none;
}
